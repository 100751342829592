<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="blue-text text-darken-3 text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row class="mt-1">
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_from"
                                            label="Start Period *"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_from"
                                            no-title
                                            type="month"
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal_to"
                                        v-model="modal_to"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_to"
                                            label="End Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_to"
                                            no-title
                                            type="month"
                                            @input="modal_to = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-domain"
                                        solo
                                        dense
                                        clearable
                                        v-model="mill"
                                        :items="mills"
                                        item-value="mill_id"
                                        :item-text="item => item.mill_id +' - '+ item.mill_name.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        label="MILL *"
                                        @change="(event) => getMachType(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-state-machine"
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_type"
                                        :items="mach_types"
                                        item-value="mach_type"
                                        :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        label="Machine Type *"
                                        :loading="loading_mach_type"
                                        @change="(event) => [getMachID(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-identifier"
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_id"
                                        :items="mach_ids"
                                        item-value="mach_id"
                                        :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :loading="loading_mach_id"
                                        label="Machine ID"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-coat-rack"
                                        clearable
                                        solo
                                        v-model="coat_mass"
                                        :items="coat_masss"
                                        item-value="coat_mass"
                                        :item-text="item => 'AS' + item.coat_mass"
                                        label=" (AS - AZ)"
                                        dense
                                        class="ma-0 border-12" 
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    icons-and-text
                    class="round_top col-sm-no-padding ml-6"
                    show-arrows
                >
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :href="'#tab-'+tab.int1"
                        active-class="active-tab"
                    >
                        {{ tab.str1 }}
                    </v-tab>

                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1"
                    >
                        <v-card class="ma-0 pb-4 round_top_29" height="auto" outlined>
                            <v-container fluid class="p-3">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card id="sumScShipmentAnalysis" outlined height="475">
                                            <v-card-text>
                                                <div class="d-flex w-100 ma-0 mb-2">
                                                    <h6 class="indigo-text m-0">Query Result: </h6>
                                                    <v-spacer />
                                                    <v-btn text color="error" class="mr-1">
                                                    <!-- Total Defect : {{$store.getters.convertToCurrencyUs(total)}} TON -->
                                                    </v-btn>
                                                </div>
                                                <v-img
                                                class="text-center mt-5 mx-auto"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="300"
                                                src="@/assets/empty_data.png"
                                                v-if="showData"
                                                ></v-img>
                                                <div id="container1" style="height: 370px; width: 100%;"></div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card class="ma-0 pb-4 round_top_29" height="auto" outlined>
                            <v-card-text class="pb-12">
                                <div class="d-flex w-100 ma-0 mb-2">
                                    <h6 class="indigo-text m-0">Query Result: </h6>
                                    <v-spacer />
                                    <v-btn text color="error" class="mr-1">
                                    <!-- Total Defect : {{$store.getters.convertToCurrencyUs(total)}} TON -->
                                    </v-btn>
                                </div>
                                <v-sheet
                                    class="mx-auto"
                                    v-if="mach_type == 'GL'"
                                >
                                    <v-slide-group
                                    multiple
                                    show-arrows
                                    >
                                        <v-slide-item>
                                            <v-card class="rounded-l" color="#e8ecef" outlined min-width="180">
                                                <v-card-title class="mb-0 pb-0">
                                                    <v-icon color="primary" class="mr-3">
                                                        mdi-information
                                                    </v-icon>
                                                    Total Waste and Off Gauge
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-divider class="mb-0 pb-0"></v-divider>
                                                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_was_he)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste FE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_was_re)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste BE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_was_cl)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste PUP</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_w_off_guage)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Off Gauge</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                        <v-slide-item class="mx-1"  v-for="(galvalium, index) in galvaliums" :key="index">
                                            <v-card class="rounded-l" color="#e8ecef" outlined min-width="180">
                                                <v-card-title class="mb-0 pb-0">
                                                    <v-icon color="primary" class="mr-3">
                                                        mdi-information
                                                    </v-icon>
                                                    {{galvalium.periode}}
                                                    <v-spacer></v-spacer>
                                                    <v-checkbox
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    dense
                                                    v-model="galvalium.check"
                                                    @change="getDetailDt(galvalium, index)"
                                                    ></v-checkbox>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-divider class="mb-0 pb-0"></v-divider>
                                                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(galvalium.was_he)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste FE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(galvalium.was_re)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste BE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(galvalium.was_cl)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste PUP</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(galvalium.w_off_guage)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Off Gauge</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-sheet>
                                <v-sheet
                                    class="mx-auto"
                                    v-else-if="mach_type == 'SL'"
                                >
                                    <v-slide-group
                                    multiple
                                    show-arrows
                                    >
                                        <v-slide-item>
                                            <v-card class="rounded-l" color="#e8ecef" outlined min-width="180">
                                                <v-card-title class="mb-0 pb-0">
                                                    <v-icon color="primary" class="mr-3">
                                                        mdi-information
                                                    </v-icon>
                                                    Total Waste
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-divider class="mb-0 pb-0"></v-divider>
                                                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_waste_pita)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste Pita</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_was_re_sl)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste BE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(total_was_cl_sl)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste FE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                        <v-slide-item class="mx-1"  v-for="(sliting, index) in slitings" :key="index">
                                            <v-card class="rounded-l" color="#e8ecef" outlined min-width="180">
                                                <v-card-title class="mb-0 pb-0">
                                                    <v-icon color="primary" class="mr-3">
                                                        mdi-information
                                                    </v-icon>
                                                    {{sliting.periode}}
                                                    <v-spacer></v-spacer>
                                                    <v-checkbox
                                                    class="ma-0 pa-0 border-12"
                                                    hide-details=true
                                                    dense
                                                    v-model="sliting.check"
                                                    @change="getDetailSliting(sliting, index)"
                                                    ></v-checkbox>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-divider class="mb-0 pb-0"></v-divider>
                                                    <v-list dense two-line class="ma-0 pl-0 pr-0 pt-0 pb-0 mt-3" color="#e8ecef">
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(sliting.waste_pita)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste Pita</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(sliting.wgt_waste_be)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste BE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item class="pl-0 pr-0" style="min-height: 40px !important;">
                                                        <v-list-item-content class="pt-0 pb-0">
                                                            <v-list-item-title style="font-size: 14px;">{{$store.getters.convertToCurrencyUs(sliting.wgt_waste_fe)}} Kg</v-list-item-title>
                                                            <v-list-item-subtitle class="font-12">Waste FE</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-sheet>
                                <v-data-table dense :headers="headers" :items="items" class="elevation-1 mt-3" :items-per-page="30"
                                    height="450" fixed-header :divider="true" :light="true" :loading="$store.state.overlay" :search="search" group-by="tr_id" show-group-by>
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search"
                                                    append-icon="mdi-magnify" label="Search" dense single-line
                                                    hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.was_he`]="{ item }">
                                        {{ item.was_he ? $store.getters.convertToCurrencyUs(item.was_he) : 0 }}
                                    </template>
                                    <template v-slot:[`item.was_re`]="{ item }">
                                        {{ item.was_re ? $store.getters.convertToCurrencyUs(item.was_re) : 0 }}
                                    </template>
                                    <template v-slot:[`item.was_cl`]="{ item }">
                                        {{ item.was_cl ? $store.getters.convertToCurrencyUs(item.was_cl) : 0 }}
                                    </template>
                                    <template v-slot:[`item.w_off_guage`]="{ item }">
                                        {{ item.w_off_guage ? $store.getters.convertToCurrencyUs(item.w_off_guage) : 0 }}
                                    </template>
                                    <template v-slot:[`item.waste_pita`]="{ item }">
                                        {{ item.waste_pita ? $store.getters.convertToCurrencyUs(item.waste_pita) : 0 }}
                                    </template>
                                    <template v-slot:[`item.wgt_waste_be`]="{ item }">
                                        {{ item.wgt_waste_be ? $store.getters.convertToCurrencyUs(item.wgt_waste_be) : 0 }}
                                    </template>
                                    <template v-slot:[`item.wgt_waste_fe`]="{ item }">
                                        {{ item.wgt_waste_fe ? $store.getters.convertToCurrencyUs(item.wgt_waste_fe) : 0 }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Entry Section',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            search: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            mach_types: [],
            mach_type: 'GL',
            mills: [],
            mill: 'SR        ',
            mach_id: '',
            mach_ids: [],
            loading_mach_id:false,
            coat_masss: [],
            coat_mass: '',
            showData:true,
            tab: 'tab-1',
            tabs: [
                { int1: 1, str1: 'Chart' },
                { int1: 2, str1: 'DataTable' }
            ],
            loading_mach_type: false,
            x: window.matchMedia("(max-width: 991px)"),
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            subyear: '',
            year: '',
            galvaliums: [],
            slitings: [],
            headers: [],
            items: [],
            search: '',
            galvalium: null,
            sliting: null
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMill()
        await this.getMachType(this.mill)
        await this.getMachID(this.mach_type)
        await this.getCoatMass()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        async getMill(){
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mill?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.mills = respData.data.data
            }
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getMachType(event){
            if (event === '' || event === null) {
                this.mach_type = ''
            } else {
                this.loading_mach_type = true
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-type?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.loading_mach_type = false
                    this.mach_types = respData.data.data
                }
            }
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                this.loading_mach_id = true
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-id?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_ids = respData.data.data
                    this.loading_mach_id = false
                }
            }
        },
        clear(){
            this.mill = ''
            this.mach_type = ''
            this.mach_id = ''
            this.coat_mass = ''
            this.date_to = ''
            this.date_from = ''
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true)  

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'mill_id': this.mill ? this.mill : '',
                'mach_type': this.mach_type ? this.mach_type : '',
                'mach_id': this.mach_id ? this.mach_id : '',
                'coat_mass': this.coat_mass ? this.coat_mass : '',
                'start': start_date ? start_date : '',
                'end': end_date ? end_date : '',
            }

            if (start_date === '' || this.mill === '' || this.mach_type === '') {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please Fill Start Period, Mill, Machine Type",
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)  

                return false
            }

            if (this.mach_type == 'GL') {
                const respData = await backendApi.fetchCore('/api/v3/sr/entry-section', reqBody, false, false, false)
                if (respData.status === 200) {
                    var container = 'container1'
                    var dt1 = []
                    var dt2 = []
                    var dt3 = []
                    var dt4 = []
                    this.galvaliums = []

                    for (let index = 0; index < respData.data.length; index++) {
                        const element = respData.data[index];
                        this.galvaliums.push({
                            periode:element.periode,
                            was_he:element.was_he,
                            was_re:element.was_re,
                            was_cl:element.was_cl,
                            w_off_guage:element.w_off_guage,
                            check: false
                        })     
                        dt1.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.was_he), periode: element.periode });
                        dt2.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.was_re), periode: element.periode });
                        dt3.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.was_cl), periode: element.periode });
                        dt4.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.w_off_guage), periode: element.periode });
                    }
                    this.showData = false
                    this.getChart(dt1, dt2, dt3, dt4, container)

                    this.$store.dispatch('setOverlay', false)  
                }
            } else if(this.mach_type == 'SL'){
                const respData = await backendApi.fetchCore('/api/v3/sr/entry-section/sliting', reqBody, false, false, false)
                if (respData.status === 200) {
                    console.log(respData.data);
                    var container = 'container1'
                    var dt1 = []
                    var dt2 = []
                    var dt3 = []
                    this.slitings = []


                    for (let index = 0; index < respData.data.length; index++) {
                        const element = respData.data[index];
                        this.slitings.push({
                            periode:element.periode,
                            waste_pita:element.waste_pita,
                            wgt_waste_be:element.wgt_waste_be,
                            wgt_waste_fe:element.wgt_waste_fe,
                            check: false
                        })     
                        dt1.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.waste_pita), periode: element.periode });
                        dt2.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.wgt_waste_be), periode: element.periode });
                        dt3.push({ label: this.monthNumToName(parseInt(element.bulan)) + ' ' + element.tahun, y: parseFloat(element.wgt_waste_fe), periode: element.periode });
                    }
                    this.showData = false
                    this.getChartSliting(dt1, dt2, dt3, container)

                    this.$store.dispatch('setOverlay', false)  
                }
            }
        },
        monthNumToName(monthnum) {
            return this.months[monthnum - 1] || '';
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(dt1, dt2, dt3, dt4, container){
          //  console.log(dt4);
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "Kg",
                        labelFormatter: this.addSymbols,
                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name: " Waste FE",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name: "Waste BE",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name:  "Waste PUP",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name: " Off Gauge",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getChartSliting(dt1, dt2, dt3, container){
          //  console.log(dt4);
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: [
                    {
                        crosshair: {
                            enabled: true,
                            snapToDataPoint: true
                        },
                        title: "Kg",
                        labelFormatter: this.addSymbols,
                    },
                ],
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                
                data: [
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name: " Waste Pita",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name: "Waste BE",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    },
                    {
                        type: "column",
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#00000",
                        name:  "Waste FE",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async getDetailDt(galvalium, index){
            this.items = []
            this.headers = []
            this.galvalium = galvalium
            this.$store.dispatch('setOverlay', true)    

            for (let i = 0; i < this.galvaliums.length; i++) {
                const element = this.galvaliums[i];

                if (i == index) {
                    element.check = true
                } else {
                    element.check = false
                }
            }

            var reqBody = {
                'periode': galvalium.periode
            }
            const respData = await backendApi.fetchCore('/api/v3/sr/entry-section/show', reqBody, false, false, false)

            if (respData.status === 200) {
                this.headers = [
                    { text: 'TR ID', value: 'tr_id', align: 'left'},
                    { text: 'Coat Mass', value: 'coat_mass', align: 'left', groupable: false},
                    { text: 'Waste FE (Kg)', value: 'was_he', align: 'right', groupable: false},
                    { text: 'Waste BE (Kg)', value: 'was_re', align: 'right', groupable: false},
                    { text: 'Waste PUP (Kg)', value: 'was_cl', align: 'right', groupable: false},
                    { text: 'Off Gauge (Kg)', value: 'w_off_guage', align: 'right', groupable: false}

                ]
                this.items = respData.data
                this.$store.dispatch('setOverlay', false)    
            } else {
                this.$store.dispatch('setOverlay', false)    
            }
        },
        async getDetailSliting(sliting, index){
            this.items = []
            this.headers = []
            this.sliting = sliting
            this.$store.dispatch('setOverlay', true)    

            for (let i = 0; i < this.slitings.length; i++) {
                const element = this.slitings[i];

                if (i == index) {
                    element.check = true
                } else {
                    element.check = false
                }
            }

            var reqBody = {
                'periode': sliting.periode
            }
            const respData = await backendApi.fetchCore('/api/v3/sr/entry-section/show/sliting', reqBody, false, false, false)

            if (respData.status === 200) {
                this.headers = [
                    { text: 'TR ID', value: 'tr_id', align: 'left'},
                    { text: 'Coat Mass', value: 'coat_mass', align: 'left', groupable: false},
                    { text: 'Waste Pita (Kg)', value: 'waste_pita', align: 'right', groupable: false},
                    { text: 'Waste BE (Kg)', value: 'wgt_waste_be', align: 'right', groupable: false},
                    { text: 'Waste FE (Kg)', value: 'wgt_waste_fe', align: 'right', groupable: false}

                ]
                this.items = respData.data
                this.$store.dispatch('setOverlay', false)    
            } else {
                this.$store.dispatch('setOverlay', false)    
            }
        }
    },
    computed:{
        total_was_he(){
			var total_was_he = 0

            for (let index = 0; index < this.galvaliums.length; index++) {
                total_was_he = total_was_he + parseFloat(this.galvaliums[index].was_he);
            }
            
            return (total_was_he | 0)
        },
        total_was_re(){
			var total_was_re = 0

            for (let index = 0; index < this.galvaliums.length; index++) {
                total_was_re = total_was_re + parseFloat(this.galvaliums[index].was_re);
            }
            
            return (total_was_re | 0)
        },
        total_was_cl(){
			var total_was_cl = 0

            for (let index = 0; index < this.galvaliums.length; index++) {
                total_was_cl = total_was_cl + parseFloat(this.galvaliums[index].was_cl);
            }
            
            return (total_was_cl | 0)
        },
        total_w_off_guage(){
			var total_w_off_guage = 0

            for (let index = 0; index < this.galvaliums.length; index++) {
                total_w_off_guage = total_w_off_guage + parseFloat(this.galvaliums[index].w_off_guage);
            }
            
            return (total_w_off_guage | 0)
        },
        total_waste_pita(){
			var total_waste_pita = 0

            for (let index = 0; index < this.slitings.length; index++) {
                total_waste_pita = total_waste_pita + parseFloat(this.slitings[index].waste_pita);
            }
            
            return (total_waste_pita | 0)
        },
        total_was_re_sl(){
			var total_was_re_sl = 0

            for (let index = 0; index < this.slitings.length; index++) {
                total_was_re_sl = total_was_re_sl + parseFloat(this.slitings[index].wgt_waste_be);
            }
            
            return (total_was_re_sl | 0)
        },
        total_was_cl_sl(){
			var total_was_cl_sl = 0

            for (let index = 0; index < this.slitings.length; index++) {
                total_was_cl_sl = total_was_cl_sl + parseFloat(this.slitings[index].wgt_waste_fe);
            }
            
            return (total_was_cl_sl | 0)
        },
    },
    watch: {
    },
}
</script>
<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>